import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  section: {
    marginBottom: 10,
  },
  logo: {
    height: 50,
    marginBottom: 20,
  },
  invoiceTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  invoiceNumber: {
    fontSize: 12,
    textAlign: 'right',
    marginBottom: 20,
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  subHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 3,
  },
  text: {
    fontSize: 12,
    marginBottom: 3,
  },
  table: {
    display: 'table',
    width: 'auto',
    margin: '10px 0',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#000',
    color: '#FFF',
    padding: 5,
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    padding: 5,
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 10,
    fontWeight: 'bold',
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
  },
  footer: {
    textAlign: 'center',
    color: 'grey',
    opacity: 0.7,
    fontSize: 10,
    marginTop: 20,
  },
  total: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'right',
  },
  balanceDue: {
    backgroundColor: '#f2f2f2',
    padding: 5,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 20,
  },
  paid: {
    color: 'green',
  },
  unpaid: {
    color: 'red',
  },
});

const formatPrice = (price) => {
  const numericPrice = parseFloat(price); // Ensure price is a number
  return numericPrice.toFixed(2); // Convert to decimal format if needed
};

const InvoiceDocument = ({ invoice }) => (
  <Document>
    <Page style={styles.page}>
      <View>
        <View style={styles.section}>
          <Text style={styles.logo}>{invoice.supplierName}</Text>
          <Text style={styles.invoiceTitle}>INVOICE</Text>
          <Text style={styles.invoiceNumber}># {invoice.id}</Text>
          <Text style={invoice.status === 'PAID' ? styles.paid : styles.unpaid}>
            {invoice.status}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.header}>Supplier Information</Text>
          <Text style={styles.text}>Company Name: {invoice.supplierName}</Text>
          <Text style={styles.text}>
            Address: {invoice.supplierAddress.line1}, {invoice.supplierAddress.line2},{' '}
            {invoice.supplierAddress.city}, {invoice.supplierAddress.state},{' '}
            {invoice.supplierAddress.postalCode}, {invoice.supplierAddress.country}
          </Text>
          <Text style={styles.text}>Email: {invoice.supplierEmail}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subHeader}>Bill to:</Text>
          <Text style={styles.text}>Customer Name: {invoice.customer_name}</Text>
          <Text style={styles.text}>Customer Email: {invoice.customer_email}</Text>
          <Text style={styles.text}>Customer Address: {invoice.customer_address}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            Date: {new Date(invoice.created_at).toLocaleDateString()}
          </Text>
          <Text style={styles.text}>Payment Terms: Due on receipt</Text>
          <Text style={styles.text}>
            Due Date: {new Date(invoice.created_at).toLocaleDateString()}
          </Text>
          <Text style={styles.text}>
            Balance Due: {formatPrice(invoice.items.reduce((acc, item) => acc + parseFloat(item.price), 0))}{' '}
            {invoice.currency.toUpperCase()}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.header}>Transaction Details</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Item</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Description</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Tax Code</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Amount</Text>
              </View>
            </View>
            {invoice.items.map((item) => (
              <View style={styles.tableRow} key={item.id}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.name}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.description}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.tax_code}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatPrice(item.price)} {invoice.currency.toUpperCase()}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <Text style={styles.total}>
            Subtotal: {formatPrice(invoice.items.reduce((acc, item) => acc + parseFloat(item.price), 0))}{' '}
            {invoice.status}
          </Text>
          <Text style={styles.total}>
            Tax:  {invoice.currency.toUpperCase()}
          </Text>
          <Text style={styles.total}>
            Total: {formatPrice(invoice.items.reduce((acc, item) => acc + parseFloat(item.price), 0))}{' '}
            {invoice.currency.toUpperCase()}
          </Text>
        </View>
        <Text style={styles.balanceDue}>
          Balance Due: {formatPrice(invoice.items.reduce((acc, item) => acc + parseFloat(item.price), 0))}{' '}
          {invoice.currency.toUpperCase()}
        </Text>
      </View>
      <Text style={styles.footer}>
        Invoice Generated by Transacly.co | Stripe Invoicing & Payment Management
      </Text>
    </Page>
  </Document>
);

export default InvoiceDocument;