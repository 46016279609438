import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function Success() {
  const navigate = useNavigate();
  const location = useLocation();
  const sessionId = new URLSearchParams(location.search).get('session_id');

  useEffect(() => {
    const updateSubscriptionStatus = async () => {
      const token = localStorage.getItem('token'); // Ensure the token is stored correctly
      try {
        await axios.post(
          'https://api.transacly.co/update-subscription',
          { sessionId },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        navigate('/dashboard');
      } catch (error) {
        console.error('Error updating subscription status:', error);
        navigate('/plans');
      }
    };

    if (sessionId) {
      updateSubscriptionStatus();
    } else {
      navigate('/plans');
    }
  }, [sessionId, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md text-center">
        <h2 className="text-2xl mb-4">Subscription Successful!</h2>
        <p>Your subscription has been successfully processed. You will be redirected to your dashboard shortly.</p>
      </div>
    </div>
  );
}

export default Success;