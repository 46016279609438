import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState(null); // 'error' or 'success'
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.transacly.co/login', { email, password });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        setMessageType('success');
        setMessage('You have logged in successfully.');
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setMessageType('error');
      setMessage('Invalid credentials. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md">
        <h2 className="text-2xl mb-4 text-center">Login to Transacly.</h2>
        {message && messageType === 'error' && (
          <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
            <span className="font-medium">{message}</span>
          </div>
        )}
        {message && messageType === 'success' && (
          <div className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50" role="alert">
            <span className="font-medium">{message}</span>
          </div>
        )}
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          className="border p-2 mb-4 w-full"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="border p-2 mb-4 w-full"
        />
        <button type="submit" className="bg-blue-500 text-white p-2 rounded w-full">Login</button>
        <div className='text-gray-500 text-center p-3'>If you need an account please click <a href="/signup" className='text-blue-400'>HERE.</a></div>
      </form>
    </div>
  );
}

export default Login;