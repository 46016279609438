import React, { useState, useEffect } from 'react';
import axios from 'axios';

const CompanyModal = ({ onClose }) => {
  const [companyInfo, setCompanyInfo] = useState({
    company_name: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    vat_number: ''
  });

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('https://api.transacly.co/api/company-info', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          if (response.data) {
            setCompanyInfo(response.data);
          }
        } catch (error) {
          console.log('Error fetching company information:', error);
        }
      }
    };

    fetchCompanyInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo({ ...companyInfo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      await axios.post('https://api.transacly.co/api/company-info', companyInfo, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      alert('Company information updated successfully');
      onClose();
    } catch (error) {
      console.log('Error updating company information:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md z-50">
        <h2 className="text-l font-semibold mb-4">Edit Company Information displayed on the invoice.</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Company Name</label>
            <input
              type="text"
              name="company_name"
              value={companyInfo.company_name}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Address Line 1</label>
            <input
              type="text"
              name="address_line1"
              value={companyInfo.address_line1}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Address Line 2</label>
            <input
              type="text"
              name="address_line2"
              value={companyInfo.address_line2}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">City</label>
            <input
              type="text"
              name="city"
              value={companyInfo.city}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">State</label>
            <input
              type="text"
              name="state"
              value={companyInfo.state}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Postal Code</label>
            <input
              type="text"
              name="postal_code"
              value={companyInfo.postal_code}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Country</label>
            <input
              type="text"
              name="country"
              value={companyInfo.country}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">VAT Number</label>
            <input
              type="text"
              name="vat_number"
              value={companyInfo.vat_number}
              onChange={handleChange}
              className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>
          <div className="flex justify-end">
            <button type="button" onClick={onClose} className="bg-gray-300 text-gray-800 p-2 rounded mr-2 hover:bg-gray-400">Cancel</button>
            <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyModal;