import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import Invoice from './components/Invoice';
import Plans from './components/Plans';
import Success from './components/Success';
import SuccessTrans from './components/SuccessTrans';
import ProductManagement from './components/ProductManagement';
import InvoiceList from './components/InvoiceList';
import InvoiceDetail from './components/InvoiceDetail'; // Ensure this is the correct path
import HomePage from './components/HomePage';
import SupportPage from './components/SupportPage';
import SubView from './components/subView';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <Router>
      <Analytics />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/success" element={<Success />} />
        <Route path="/productManagement" element={<ProductManagement />} />
        <Route path="/invoiceList" element={<InvoiceList />} />
        <Route path="/invoice/:invoiceUURL" element={<InvoiceDetail />} /> {/* Make sure InvoiceDetail uses useParams */}
        <Route path="/successTransacly" element={<SuccessTrans />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/support" element={<SupportPage />} /> {/* Make sure to match the case sensitivity */}
        <Route path="/subscriptions" element={<SubView />} /> {/* Make sure to match the case sensitivity */}
      </Routes>
    </Router>
  );
}
export default App;