import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function DashGraph({ dailyTotals, isDarkMode }) {
  return (
    <div className="relative w-full h-96">
      {/* Enhanced Watermark Text */}
      <div className="absolute inset-0 flex justify-center items-center pointer-events-none">
        <h1
          className="text-8xl font-bold opacity-10 select-none"
          style={{
            color: isDarkMode ? '#4c51bf' : '#8884d8',
            textShadow: isDarkMode
              ? '2px 2px 5px rgba(0, 0, 0, 0.5)'
              : '2px 2px 5px rgba(255, 255, 255, 0.5)',
          }}
        >
          Transacly.co
        </h1>
      </div>

      {/* Graph */}
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart data={dailyTotals}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={isDarkMode ? '#4c51bf' : '#8884d8'} stopOpacity={0.8} />
              <stop offset="95%" stopColor={isDarkMode ? '#4c51bf' : '#8884d8'} stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke={isDarkMode ? '#505050' : '#ccc'} />
          <XAxis dataKey="date" stroke={isDarkMode ? '#fff' : '#000'} />
          <YAxis stroke={isDarkMode ? '#fff' : '#000'} />
          <Tooltip contentStyle={{ backgroundColor: isDarkMode ? '#333' : '#fff', color: isDarkMode ? '#fff' : '#000' }} />
          <Legend />
          <Area type="monotone" dataKey="amount" stroke={isDarkMode ? '#4c51bf' : '#8884d8'} fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DashGraph;