// src/authHelpers.js
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const checkJwtExpiration = async (navigate) => {
  const token = localStorage.getItem('token');
  if (!token) {
    navigate('/login');
    return false;
  }

  try {
    const response = await axios.get('https://api.transacly.co/check-subscription', {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!response.data.hasSubscription) {
      navigate('/plans');
      return false;
    }
    return true;
  } catch (error) {
    console.error('Error checking token validity:', error);
    localStorage.removeItem('token');
    navigate('/login');
    return false;
  }
};