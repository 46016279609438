import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CompanyModal from './CompanyModal';
import { checkJwtExpiration } from '../auth-helpers';

const NavigationBar = ({ handleLogout }) => {
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State for sidebar toggle
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      await checkJwtExpiration(navigate);
    };

    validateToken();
    const interval = setInterval(validateToken, 60000);

    return () => clearInterval(interval);
  }, [navigate]);

  const handleInvoicesClick = () => {
    navigate('/invoiceList');
    setMenuOpen(false); // Close sidebar after click
  };

  const handleSubClick = () =>{
    navigate('/subscriptions');
    setMenuOpen(false);
  };

  const handleProductsClick = () => {
    navigate('/productManagement');
    setMenuOpen(false); // Close sidebar after click
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
    setMenuOpen(false); // Close sidebar after click
  };

  const handleSupportClick = () => {
    navigate('/support');
    setMenuOpen(false); // Close sidebar after click
  };

  const handleCloseModal = () => {
    setShowCompanyModal(false);
  };

  return (
    <>
      {/* Top Navigation Bar */}
      <nav className="bg-transparent md:fixed md:top-0 md:left-0 md:right-0 z-10 relative">
        <div className="flex justify-between items-center px-4 py-4">
          <div className="flex items-center">
            {/* Hamburger Menu for Sidebar */}
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-gray-800 focus:outline-none md:hidden z-20 relative"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                />
              </svg>
            </button>
            <h1 className="text-xl font-bold ml-4">Transacly.co</h1> {/* Transacly.co Branding */}
          </div>

          <div className="flex space-x-4 pr-4">
            <button
              className="bg-white text-gray-800 border border-gray-300 p-2 rounded transition duration-200 hover:bg-purple-100 hover:bg-opacity-50"
              onClick={handleSupportClick}
            >
              Support
            </button>
            <button
              onClick={handleLogout}
              className="bg-red-500 text-white border border-red-500 p-2 rounded transition duration-200 hover:bg-red-600"
            >
              Logout
            </button>
          </div>
        </div>
      </nav>

      {/* Sidebar Navigation */}
      <div
        className={`fixed inset-y-0 left-0 w-64 bg-white shadow-md z-20 transform ${
          menuOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 md:translate-x-0`}
      >
        <div className="px-4 py-6">
          <h1 className="text-xl font-bold mb-6">Transacly.co</h1> {/* Transacly.co at the top of the sidebar */}
          {/* Overview Section */}
          <div className="mb-6">
            <h2 className="text-md mb-2 text-gray-700 font-light">Overview</h2>
            <button
              className="block w-full text-left px-4 py-2 text-gray-600 font-light hover:bg-gray-200 hover:text-gray-900 transition"
              onClick={handleDashboardClick}
            >
              Dashboard
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-gray-600 font-light hover:bg-gray-200 hover:text-gray-900 transition"
              onClick={handleInvoicesClick}
            >
              Invoices
            </button>
          </div>

          {/* Manage Section */}
          <div className="mb-6">
            <h2 className="text-md mb-2 text-gray-700 font-light">Manage</h2>
            <button
              className="block w-full text-left px-4 py-2 text-gray-600 font-light hover:bg-gray-200 hover:text-gray-900 transition"
              onClick={handleProductsClick}
            >
              Products
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-gray-600 font-light hover:bg-gray-200 hover:text-gray-900 transition"
              onClick={handleSubClick}
            >
              Subscriptions
            </button>
          </div>

          {/* Settings Section */}
          <div>
            <h2 className="text-md mb-2 text-gray-700 font-light">Settings</h2>
            <button
              className="block w-full text-left px-4 py-2 text-gray-600 font-light hover:bg-gray-200 hover:text-gray-900 transition"
              onClick={() => setShowCompanyModal(true)}
            >
              Edit Company Info
            </button>
            <button
              className="block w-full text-left px-4 py-2 text-gray-600 font-light hover:bg-gray-200 hover:text-gray-900 transition"
              onClick={() => alert('Security feature not yet built.')}
            >
              Security
            </button>
          </div>
        </div>
      </div>

      {showCompanyModal && <CompanyModal onClose={handleCloseModal} />}
    </>
  );
};

export default NavigationBar;