import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';

const SuccessTrans = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sessionId = searchParams.get('session_id');
  const invoiceId = searchParams.get('invoice_id');
  const [verificationStatus, setVerificationStatus] = useState(null); // Add state for verification status

  useEffect(() => {
    const verifyPayment = async () => {
      if (!sessionId) {
        setVerificationStatus('No session ID found');
        return;
      }

      try {
        console.log('Verifying payment for session ID:', sessionId); // Log session ID
        const response = await axios.get(`https://api.transacly.co/api/verify-payment?session_id=${sessionId}&invoice_id=${invoiceId}`);
        
        if (response.data.message === 'Payment successful') {
          setVerificationStatus('success');
          console.log('Payment verification successful');
        } else {
          setVerificationStatus('failed');
          console.log('Payment verification failed');
        }
      } catch (err) {
        setVerificationStatus('error');
        console.error('Error verifying payment:', err);
      }
    };

    if (sessionId) {
      verifyPayment();
    }
  }, [sessionId, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md text-center">
        <h3 className="text-xl mb-4">Payment Status</h3>
        {verificationStatus === 'success' ? (
          <>
            <div className="text-6xl text-green-500 mb-4">✔️</div>
            <p className="text-gray-700 mb-4">Thank you for your payment. Your invoice has been marked as paid.</p>
          </>
        ) : verificationStatus === 'failed' ? (
          <>
            <div className="text-6xl text-red-500 mb-4">❌</div>
            <p className="text-gray-700 mb-4">Payment verification failed. Please contact support if this issue persists.</p>
          </>
        ) : verificationStatus === 'error' ? (
          <>
            <div className="text-6xl text-yellow-500 mb-4">⚠️</div>
            <p className="text-gray-700 mb-4">There was an error verifying your payment. Please try again later.</p>
          </>
        ) : (
          <>
            <div className="text-6xl text-blue-500 mb-4">⏳</div>
            <p className="text-gray-700 mb-4">Verifying payment...</p>
          </>
        )}
      </div>
    </div>
  );
};

export default SuccessTrans;