import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import axios from 'axios';
import { FaDownload } from 'react-icons/fa';
import EditInvoiceForm from './EditInvoiceForm';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  section: {
    marginBottom: 10,
  },
  logo: {
    height: 50,
    marginBottom: 20,
  },
  invoiceTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'right',
  },
  invoiceNumber: {
    fontSize: 12,
    textAlign: 'right',
    marginBottom: 20,
  },
  header: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  subHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 3,
  },
  text: {
    fontSize: 12,
    marginBottom: 3,
  },
  table: {
    display: 'table',
    width: 'auto',
    margin: '10px 0',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    backgroundColor: '#000',
    color: '#FFF',
    padding: 5,
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    padding: 5,
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 10,
    fontWeight: 'bold',
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
  },
  footer: {
    textAlign: 'center',
    color: 'grey',
    opacity: 0.7,
    fontSize: 10,
    marginTop: 20,
  },
  total: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 10,
    textAlign: 'right',
  },
  balanceDue: {
    backgroundColor: '#f2f2f2',
    padding: 5,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 20,
  },
  paid: {
    color: 'green',
  },
  unpaid: {
    color: 'red',
  },
});

const formatPrice = (price) => {
  return (price / 100).toFixed(2); // Convert cents to dollars/pounds/etc.
};

const InvoiceDocument = ({ invoice }) => (
  <Document>
    <Page style={styles.page}>
      <View>
        <View style={styles.section}>
          <Text style={styles.logo}>{invoice.supplierName}</Text>
          <Text style={styles.invoiceTitle}>INVOICE</Text>
          <Text style={styles.invoiceNumber}>4 {invoice.id}</Text>
          <Text style={invoice.status === 'PAID' ? styles.paid : styles.unpaid}>
            {invoice.status}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.header}>Supplier Information</Text>
          <Text style={styles.text}>Company Name: {invoice.supplierName}</Text>
          <Text style={styles.text}>
            Address: {invoice.supplierAddress.line1}, {invoice.supplierAddress.line2},{' '}
            {invoice.supplierAddress.city}, {invoice.supplierAddress.state},{' '}
            {invoice.supplierAddress.postalCode}, {invoice.supplierAddress.country}
          </Text>
          <Text style={styles.text}>Email: {invoice.supplierEmail}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subHeader}>Bill to:</Text>
          <Text style={styles.text}>Customer Name: {invoice.customer_name}</Text>
          <Text style={styles.text}>Customer Email: {invoice.customer_email}</Text>
          <Text style={styles.text}>Customer Address: {invoice.customer_address}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            Date: {new Date(invoice.created_at).toLocaleDateString()}
          </Text>
          <Text style={styles.text}>Payment Terms: Due on receipt</Text>
          <Text style={styles.text}>
            Due Date: {new Date(invoice.created_at).toLocaleDateString()}
          </Text>
          <Text style={styles.text}>
            Balance Due: {formatPrice(invoice.items.reduce((acc, item) => acc + item.price, 0))}{' '}
            {invoice.currency.toUpperCase()}
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.header}>Transaction Details</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Item</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Description</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Tax Code</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Amount</Text>
              </View>
            </View>
            {invoice.items.map((item) => (
              <View style={styles.tableRow} key={item.id}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.product_id}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.description}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{item.tax_code}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {formatPrice(item.price)} {invoice.currency.toUpperCase()}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <Text style={styles.total}>
            Subtotal: {formatPrice(invoice.items.reduce((acc, item) => acc + item.price, 0))}{' '}
            {invoice.currency.toUpperCase()}
          </Text>
          <Text style={styles.total}>
            Tax (0%): 0.00 {invoice.currency.toUpperCase()}
          </Text>
          <Text style={styles.total}>
            Total: {formatPrice(invoice.items.reduce((acc, item) => acc + item.price, 0))}{' '}
            {invoice.currency.toUpperCase()}
          </Text>
        </View>
        <Text style={styles.balanceDue}>
          Balance Due: {formatPrice(invoice.items.reduce((acc, item) => acc + item.price, 0))}{' '}
          {invoice.currency.toUpperCase()}
        </Text>
      </View>
      <Text style={styles.footer}>
        Invoice Generated by Transacly.co | Stripe Invoicing & Payment Management system
      </Text>
    </Page>
  </Document>
);

const Invoice = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialInvoice = location.state?.invoice;

  const [invoice, setInvoice] = useState({
    ...initialInvoice,
    supplierName: '',
    supplierAddress: '',
    supplierVatNumber: '',
  });

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('https://api.transacly.co/api/company-info', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          if (response.data) {
            const { company_name, address_line1, address_line2, city, state, postal_code, country, vat_number } = response.data;
            setInvoice(prevInvoice => ({
              ...prevInvoice,
              supplierName: company_name,
              supplierAddress: `${address_line1}${address_line2 ? `, ${address_line2}` : ''}, ${city}, ${state ? `${state}, ` : ''}${postal_code}, ${country}`,
              supplierVatNumber: vat_number || 'N/A'
            }));
          }
        } catch (error) {
          console.log('Error fetching company information:', error);
        }
      }
    };

    if (!initialInvoice) {
      navigate('/dashboard'); // Redirect to dashboard if no invoice data
    } else {
      fetchCompanyInfo();
    }
  }, [initialInvoice, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      [name]: value,
    }));
  };

  if (!initialInvoice) {
    return null; // Render nothing if no initial invoice data
  }

  return (
    <div className="min-h-screen flex flex-row bg-gray-100 p-6">
      <div className="w-full max-w-md p-4 bg-white rounded shadow-md mr-4">
        <h1 className="text-2xl font-bold mb-4">Edit Invoice</h1>
        <EditInvoiceForm invoice={invoice} handleChange={handleChange} />
        <div className="flex justify-between mt-4">
          <button onClick={() => navigate('/dashboard')} className="bg-white text-gray-800 border border-gray-300 p-2 rounded">Back to Dashboard</button>
          <PDFDownloadLink document={<InvoiceDocument invoice={invoice} />} fileName="invoice.pdf">
            {({ loading }) =>
              loading ? (
                <button className="bg-white text-gray-800 border border-gray-300 p-2 rounded flex items-center">
                  Loading...
                </button>
              ) : (
                <button className="bg-white text-gray-800 border border-gray-300 p-2 rounded flex items-center">
                  <FaDownload className="mr-2" /> Download PDF
                </button>
              )
            }
          </PDFDownloadLink>
        </div>
      </div>
      <div className="flex-1">
        <PDFViewer style={{ width: '100%', height: '100%' }}>
          <InvoiceDocument invoice={invoice} />
        </PDFViewer>
      </div>
    </div>
  );
};

export default Invoice;