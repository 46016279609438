import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

Modal.setAppElement('#root'); // Ensures accessibility

function DashStatements() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date()); // Default to today's date
  const [endDate, setEndDate] = useState(new Date()); // Default to today's date
  const [loading, setLoading] = useState(false);

  // Open modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Set date range to Year-to-Date
  const setYearToDate = () => {
    const startOfYear = moment().startOf('year').toDate();
    const today = new Date();
    setStartDate(startOfYear);
    setEndDate(today);
  };

  const handleDownload = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api.transacly.co/api/transactions/all', {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      // Filter transactions based on selected date range
      const filteredTransactions = response.data.filter(transaction => {
        const transactionDate = moment(transaction.date, 'MM/DD/YYYY');
        return transactionDate.isBetween(moment(startDate), moment(endDate), 'day', '[]');
      });
  
      if (filteredTransactions.length === 0) {
        alert("No transactions found for the selected date range.");
        setLoading(false);
        return;
      }
  
      // Initialize PDF document
      const doc = new jsPDF('p', 'pt', 'letter');
  
      // Title at the top
      doc.setFontSize(18);
      doc.text('Statement from Transacly.co', 40, 40);
  
      // Subtitle with date range
      doc.setFontSize(12);
      doc.text(
        `Statement from ${moment(startDate).format('YYYY-MM-DD')} to ${moment(endDate).format('YYYY-MM-DD')}`,
        40,
        70
      );
  
      // Table Headers
      const tableColumn = ["Transaction ID", "Type", "Description", "Date", "Amount"];
      const tableRows = [];
  
      // Prepare transaction data for the table
      filteredTransactions.forEach(transaction => {
        const transactionData = [
          transaction.id,
          transaction.type, // Include type (Charge, Refund, Payout, Transfer)
          transaction.description,
          moment(transaction.date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          `$${transaction.amount.toFixed(2)}` // Format amount as currency
        ];
        tableRows.push(transactionData);
      });
  
      // Add the table to the PDF
      doc.autoTable({
        startY: 100,
        head: [tableColumn],
        body: tableRows,
        margin: { top: 10 },
      });
  
      const pageCount = doc.internal.getNumberOfPages();
  
      // Footer with watermark and page numbers
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
  
        // Page number in the bottom-right corner
        doc.setFontSize(10);
        doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.width - 60, doc.internal.pageSize.height - 30);
  
        // Watermark text
        doc.setFontSize(8);
        doc.setTextColor(150);
        doc.text(
          "Transacly.co | A stripe transactions management site | A Proxitech Company",
          doc.internal.pageSize.width / 2,
          doc.internal.pageSize.height - 20,
          { align: 'center' }
        );
      }
  
      // Save the PDF with the current date
      doc.save(`statement_${moment().format('YYYYMMDD')}.pdf`);
  
      closeModal();
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Statement Download Button */}
      <button onClick={openModal} className="bg-blue-500 text-white p-2 rounded shadow-md">
        Download Statement
      </button>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Select Time Frame"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '600px',
            padding: '50px',
            paddingTop: '50px',
            paddingBottom: '200px',
            borderRadius: '10px',
            backgroundColor: '#f8f9fa',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      >
        <h2 className="text-xl font-semibold mb-6 text-center">Select Date Range</h2>

        <div className="flex justify-between items-center mb-4">
          <div className="flex flex-col w-full">
            <label className="block text-gray-700 text-sm font-bold mb-2">Start Date:</label>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              className="border p-2 rounded w-full"
              dateFormat="yyyy-MM-dd"
            />
          </div>

          <span className="mx-4 text-lg font-semibold">TO</span>

          <div className="flex flex-col w-full">
            <label className="block text-gray-700 text-sm font-bold mb-2">End Date:</label>
            <DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              className="border p-2 rounded w-full"
              dateFormat="yyyy-MM-dd"
            />
          </div>
        </div>

        {/* Preset Option */}
        <button 
          onClick={setYearToDate} 
          className="bg-blue-500 text-white p-2 rounded shadow-md w-full mb-10"
        >
          Year to Date Statement
        </button>

        <div className="flex justify-between">
          <button 
            onClick={closeModal} 
            className="bg-gray-500 text-white p-2 rounded shadow-md"
          >
            Cancel
          </button>
          <button 
            onClick={handleDownload} 
            className={`bg-blue-500 text-white p-2 rounded shadow-md ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} 
            disabled={loading}
          >
            {loading ? 'Downloading...' : 'Download PDF'}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default DashStatements;