// PM-Create-Form.js
import React from 'react';

const PMCreateForm = ({
  newProduct,
  setNewProduct,
  handleCreateProduct,
  isDarkMode,
  errors,
  stripeAcceptedCurrencies,
  taxCodes,
}) => {
  return (
    <div className={`p-6 rounded shadow-md mb-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
      <h4 className="text-lg mb-2">Create New Product</h4>
      <input
        type="text"
        placeholder="Name"
        value={newProduct.name}
        onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
        className={`border p-2 rounded mb-2 w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
      />
      {errors.name && <span className="text-red-500 text-sm">{errors.name}</span>}
      <textarea
        placeholder="Description"
        value={newProduct.description}
        onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
        className={`border p-2 rounded mb-2 w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
        rows="3"
      />
      <input
        type="number"
        placeholder="Price"
        value={newProduct.price}
        onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
        className={`border p-2 rounded mb-2 w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
      />
      {errors.price && <span className="text-red-500 text-sm">{errors.price}</span>}
      <select
        value={newProduct.currency}
        onChange={(e) => setNewProduct({ ...newProduct, currency: e.target.value })}
        className={`border p-2 rounded mb-2 w-full bg-white appearance-none ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
      >
        <option value="">Select a currency</option>
        {stripeAcceptedCurrencies.map((currency) => (
          <option key={currency} value={currency}>
            {currency.toUpperCase()}
          </option>
        ))}
      </select>
      <select
        value={newProduct.interval}
        onChange={(e) => setNewProduct({ ...newProduct, interval: e.target.value })}
        className={`border p-2 rounded mb-2 w-full bg-white appearance-none ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
      >
        <option value="">Select an interval</option>
        <option value="day">Daily</option>
        <option value="week">Weekly</option>
        <option value="month">Monthly</option>
        <option value="year">Yearly</option>
      </select>
      <select
        value={newProduct.taxCode}
        onChange={(e) => setNewProduct({ ...newProduct, taxCode: e.target.value })}
        className={`border p-2 rounded mb-2 w-full bg-white appearance-none ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
      >
        <option value="">Select a tax code</option>
        {taxCodes.map((code) => (
          <option key={code.id} value={code.id}>
            {code.name}
          </option>
        ))}
      </select>
      {errors.taxCode && <span className="text-red-500 text-sm">{errors.taxCode}</span>}
      <button
        onClick={handleCreateProduct}
        className="bg-blue-500 text-white px-4 py-2 rounded transition-transform transform hover:scale-105"
      >
        Create +
      </button>
    </div>
  );
};

export default PMCreateForm;