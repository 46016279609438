import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import ReactPaginate from 'react-paginate';

function Subscriptions() {
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalMRR, setTotalMRR] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const subscriptionsPerPage = 15; // Subscriptions per page

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://api.transacly.co/api/subscriptions', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setSubscriptions(response.data.subscriptions);
        setTotalMRR(response.data.totalMRR);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };

    fetchSubscriptions();
  }, []);

  // Calculate the current page subscriptions
  const pageCount = Math.ceil(subscriptions.length / subscriptionsPerPage);
  const currentSubscriptions = subscriptions.slice(
    currentPage * subscriptionsPerPage,
    (currentPage + 1) * subscriptionsPerPage
  );

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleCancelSubscription = async (subscriptionId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        'https://api.transacly.co/api/cancel-subscription', // Correct URL
        { subscriptionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // Remove the canceled subscription from the list
      setSubscriptions(subscriptions.filter(sub => sub.id !== subscriptionId));
    } catch (error) {
      console.error('Error cancelling subscription:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <NavigationBar handleLogout={handleLogout} />

      <div className="pt-20 md:ml-64 p-6">
        <div className="pb-6">
          <h1 className="text-3xl font-bold mb-4">Subscriptions</h1>
        </div>

        {/* Top Stats */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="p-6 bg-white shadow-md rounded-lg">
            <h3 className="text-lg font-semibold">Active Subscriptions</h3>
            <p className="text-2xl font-bold text-gray-800">{subscriptions.length}</p>
          </div>
          <div className="p-6 bg-white shadow-md rounded-lg">
            <h3 className="text-lg font-semibold">Monthly Recurring Revenue (MRR)</h3>
            <p className="text-2xl font-bold text-gray-800">${totalMRR}</p>
          </div>
        </div>

        {/* Subscriptions Table */}
        {loading ? (
          <div className="text-center py-10">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500"></div>
            <p className="mt-4 text-lg text-gray-600">Loading subscriptions...</p>
          </div>
        ) : (
          <>
            <div className="overflow-x-auto">
              <table className="table-auto w-full bg-white shadow-md rounded-lg">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Subscription ID</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Subscriber Email</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Amount</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">Next Billing Date</th>
                    <th className="px-6 py-3 text-center text-xs font-semibold text-gray-700 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {currentSubscriptions.map((subscription, index) => (
                    <tr key={subscription.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{subscription.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{subscription.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${subscription.amount}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{subscription.next_billing_date}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-center">
                        <button
                          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                          onClick={() => handleCancelSubscription(subscription.id)}
                        >
                          Cancel
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className="flex justify-center py-4">
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'bg-blue-500 text-white'}
                pageClassName={'inline-block px-3 py-1 rounded-md border border-gray-300 hover:bg-blue-100'}
                previousClassName={'inline-block px-3 py-1 rounded-md border border-gray-300 hover:bg-blue-100'}
                nextClassName={'inline-block px-3 py-1 rounded-md border border-gray-300 hover:bg-blue-100'}
                disabledClassName={'inline-block px-3 py-1 rounded-md border border-gray-300 text-gray-400'}
                activeLinkClassName={'bg-blue-500 text-white px-3 py-1 rounded-md'}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Subscriptions;