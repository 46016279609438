import React from 'react';

const EditInvoiceForm = ({ invoice, handleChange }) => (
  <>
    <div className="mb-4">
      <h3 className="text-lg mb-2">Supplier Information</h3>
      <input
        type="text"
        name="supplierName"
        value={invoice.supplierName}
        onChange={handleChange}
        className="border p-2 mb-2 w-full"
        placeholder="Company Name"
      />
      <input
        type="text"
        name="supplierAddress"
        value={invoice.supplierAddress}
        onChange={handleChange}
        className="border p-2 mb-2 w-full"
        placeholder="Company Address"
      />
      <input
        type="text"
        name="supplierVatNumber"
        value={invoice.supplierVatNumber}
        onChange={handleChange}
        className="border p-2 mb-2 w-full"
        placeholder="VAT Number"
      />
    </div>
    <div className="mb-4">
      <h3 className="text-lg mb-2">Customer Information</h3>
      <input
        type="text"
        name="customerName"
        value={invoice.customerName}
        onChange={handleChange}
        className="border p-2 mb-2 w-full"
        placeholder="Customer Name"
      />
      <input
        type="text"
        name="customerEmail"
        value={invoice.customerEmail}
        onChange={handleChange}
        className="border p-2 mb-2 w-full"
        placeholder="Customer Email"
      />
      <input
        type="text"
        name="customerPhone"
        value={invoice.customerPhone}
        onChange={handleChange}
        className="border p-2 mb-2 w-full"
        placeholder="Customer Phone"
      />
      <input
        type="text"
        name="customerAddress"
        value={invoice.customerAddress}
        onChange={handleChange}
        className="border p-2 mb-2 w-full"
        placeholder="Customer Address"
      />
      <input
        type="text"
        name="customerVatNumber"
        value={invoice.customerVatNumber}
        onChange={handleChange}
        className="border p-2 mb-2 w-full"
        placeholder="Customer VAT Number"
      />
    </div>
    <div className="mb-4">
      <h3 className="text-lg mb-2">Description</h3>
      <input
        type="text"
        name="description"
        value={invoice.description}
        onChange={handleChange}
        className="border p-2 mb-2 w-full"
        placeholder="Description"
      />
    </div>
  </>
);

export default EditInvoiceForm;