import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '@fortawesome/fontawesome-free/css/all.min.css';

const HomePage = () => {
  const [faqOpen, setFaqOpen] = useState(null);
  const [metaTitle, setMetaTitle] = useState('Transacly - Effortless Invoice Management');
  const [metaDescription, setMetaDescription] = useState('Transacly offers effortless invoice management solutions with easy-to-use tools to generate, send, and track invoices.');

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  useEffect(() => {
    // Example of AI-driven dynamic meta optimization
    const optimizedTitle = 'Efficient Invoice Management with Transacly - Optimize Payments';
    const optimizedDescription = 'Boost productivity with Transacly, the AI-optimized invoicing tool for seamless management and tracking of transactions.';

    setMetaTitle(optimizedTitle);
    setMetaDescription(optimizedDescription);

    // Dynamic CTA adjustment
    const ctaText = "Sign Up Today!";
    setCtaText(ctaText);
  }, []);

  // Dynamic CTA text state
  const [ctaText, setCtaText] = useState('Get Started');

  return (
    <div className="min-h-screen bg-white">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content="Invoicing, payment management, stripe integration, automation, online payments, Transacly" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Transacly" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph for Social Media */}
        <meta property="og:title" content="Efficient Invoice Management with Transacly - Optimize Payments" />
    <meta property="og:description" content="Boost productivity with Transacly, the optimized invoicing tool for seamless management and tracking of transactions." />
    <meta property="og:image" content="https://transacly.co/x-image-card.png" />
    <meta property="og:url" content="https://transacly.co/" />
    <meta property="og:type" content="website" />

        {/* Twitter Cards */}
        <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content='Transacly - Effortless Invoice Management' />
    <meta name="twitter:description" content='Transacly offers effortless invoice management solutions with easy-to-use tools to generate, send, and track invoices'/>
    <meta name="twitter:image" content="https://transacly.co/x-image-card.png" />
    <meta name="twitter:site" content="@Transacly" />
      </Helmet>

      <style jsx>{`
        .gradient-text {
          background: linear-gradient(90deg, #6b46c1, #805ad5);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .features-list li::before {
          content: '\\2022';
          color: #6b46c1;
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
        .faq-question::after {
          content: '\\25BC';
          float: right;
          transition: transform 0.3s;
        }
        .faq-question.open::after {
          transform: rotate(180deg);
        }
        .section-gradient {
          background: linear-gradient(180deg, var(--tw-gradient-stops));
        }
        .bg-fade-white-to-gray {
          --tw-gradient-stops: white, #f3f4f6;
        }
        .bg-fade-gray-to-white {
          --tw-gradient-stops: #f3f4f6, white;
        }
        .bg-fade-white-to-indigo {
          --tw-gradient-stops: white, #ebf4ff;
        }
        .bg-fade-indigo-to-white {
          --tw-gradient-stops: #ebf4ff, white;
        }
      `}</style>

      {/* Top Nav/Header Section */}
      <header className="top-0 ease-in-out bg-white shadow-md">
        <div className="mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center w-full">
          <div className="flex items-center">
            <h1 className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-indigo-600 transition-colors duration-300">Transacly.</h1>
          </div>
          <nav>
            <a href="/login" className="ml-4 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50">Login</a>
            <a href="/signup" className="ml-4 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50">Sign Up</a>
          </nav>
        </div>
      </header>

      <main>
        {/* Full-Screen Hero Section */}
        <div id="hero" className="relative min-h-screen flex items-center justify-center bg-white section-gradient bg-fade-white-to-indigo">
          <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: "url('/hero-background.jpg')" }}></div>
          <div className="relative z-10 p-8 rounded-lg w-full text-center">
            <h2 className="text-4xl font-extrabold gradient-text sm:text-5xl">Welcome to Transacly.</h2>
            <p className="mt-4 text-lg text-gray-700">Effortless Invoice Management - Generate, Send, and Track Invoices with Ease</p>
            <div className="mt-8">
              <a href="/signup" className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-full text-white bg-indigo-600">
                {ctaText}
              </a>
            </div>
          </div>
        </div>

        {/* Invoicing Section */}
        <div className="py-12 bg-white section-gradient bg-fade-indigo-to-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:text-center">
            <h2 className="text-3xl font-extrabold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-indigo-600 sm:text-4xl">
              Ready to streamline your invoicing process?
            </h2>
            <p className="mt-4 text-lg leading-6 text-gray-700">
              Sign up now and experience the efficiency of Transacly.
            </p>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex rounded-md shadow">
                <a href="/signup" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Transactions View Section */}
        <div id="transactions" className="py-12 section-gradient bg-fade-white-to-gray">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center">
            <div className="lg:w-1/2 pr-4">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Invoice Management
              </h2>
              <p className="mt-4 text-xl text-gray-500">
              Effortlessly create, send, and manage invoices. Our intuitive platform makes billing simple and efficient. Get paid easier with Transacly.
              </p>
            </div>
            <div className="lg:w-1/2 mt-8 lg:mt-0">
              <div className="relative">
                <img src="/image1.png" alt="Transactions Dashboard" className="relative rounded-lg shadow-lg opacity-80" loading="lazy" />
              </div>
            </div>
          </div>
        </div>

        {/* Invoice Management Section */}
        <div id="invoices" className="py-12 bg-gray-100 section-gradient bg-fade-gray-to-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:flex lg:items-center">
            <div className="lg:w-1/2 lg:order-2 pl-4">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                View Your Transactions
              </h2>
              <p className="mt-4 text-xl text-gray-500">
                Get a comprehensive view of all your Stripe transactions in one place. Easily track and manage your financial activity.
              </p>
            </div>
            <div className="lg:w-1/2 mt-8 lg:mt-0 lg:order-1">
              <div className="relative">
                <img src="image2.png" alt="Invoice Management" className="relative rounded-lg shadow-lg opacity-80" loading="lazy" />
              </div>
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="py-12 section-gradient bg-fade-white-to-gray">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Saving Time & Money</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Save Money with Transacly
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Start saving in three simple steps. No more Stripe Invoice Fees.
              </p>
            </div>
            <div className="mt-10">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow-md">
                  <h3 className="text-lg font-medium text-gray-900">1. Create an account</h3>
                  <p className="mt-2 text-base text-gray-500">Sign up and set up your account in minutes.</p>
                </div>
                <div className="p-6 bg-white rounded-lg shadow-md">
                  <h3 className="text-lg font-medium text-gray-900">2. Add your Stripe Keys</h3>
                  <p className="mt-2 text-base text-gray-500">Securely integrate your Stripe account.</p>
                </div>
                <div className="p-6 bg-white rounded-lg shadow-md">
                  <h3 className="text-lg font-medium text-gray-900">3. Enter your company info</h3>
                  <p className="mt-2 text-base text-gray-500">Provide your business information for billing purposes.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Features Section */}
        <div id="features" className="py-12 bg-white section-gradient bg-fade-gray-to-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Features</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Everything you need to manage your invoices
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Our platform provides you with the tools you need to manage your invoices efficiently.
              </p>
            </div>
            <div className="mt-10 text-left">
              <ul className="features-list space-y-4 pl-5 text-lg text-gray-700">
                <li>Generate Invoices with just a few clicks</li>
                <li>Send invoices to your customers via email easily</li>
                <li>Create Products for your invoices</li>
                <li>Set up and manage Monthly Billing for your products</li>
                <li>Access and view all your Stripe transactions</li>
                <li>Download transaction statements from your Stripe transactions</li>
              </ul>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div id="faq" className="py-12 bg-gray-100 section-gradient bg-fade-white-to-gray">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Frequently Asked Questions</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Have Questions? We’ve Got Answers
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                Here are some common questions about our platform.
              </p>
            </div>
            <div className="mt-10">
              <div className="space-y-4">
                {[
                  { question: "What is Transacly?", answer: "Transacly is a platform designed to streamline your invoicing process. You can generate, send, and manage invoices with ease." },
                  { question: "How do I create an account?", answer: "Simply click on the 'Sign Up' button on the homepage and fill in your details to create an account." },
                  { question: "How do I integrate my Stripe account?", answer: "You add your stripe API keys during signup (step 2), this is the only time you will be required to enter them, they are stored securely on our servers." },
                  { question: "Can I download transaction statements?", answer: "Yes, you can download transaction statements based on monthly, weekly, or yearly transactions." },
                  { question: "What are the pricing plans?", answer: "We offer flexible pricing plans: Monthly at £5.99/month, Yearly at £54.98/year, and an Enterprise plan with bespoke features. Please refer to the 'Plans' section for more details." },
                  { question: "Is my data secure?", answer: "Yes, we prioritize data security. We use Stripe API to handle payments and products, ensuring that your data remains secure and accessible even if you choose to leave our platform." },
                ].map((faq, index) => (
                  <div key={index} className="border-b border-gray-200 pb-4">
                    <button
                      onClick={() => toggleFaq(index)}
                      className={`faq-question w-full text-left text-lg font-medium text-gray-900 focus:outline-none ${faqOpen === index ? 'open' : ''}`}
                    >
                      {faq.question}
                    </button>
                    {faqOpen === index && (
                      <div className="mt-2 text-base text-gray-500">
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Plans Section */}
        <div id="plans" className="py-12 section-gradient bg-fade-gray-to-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="lg:text-center">
              <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Plans</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Choose the plan that fits your needs
              </p>
              <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                We offer flexible pricing options to suit your business needs.
              </p>
            </div>

            <div className="mt-10">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div className="p-6 bg-white rounded-lg shadow-md border shadow-indigo-600">
                  <h3 className="text-lg font-medium text-indigo-500 rounded-full bg-indigo-200 text-center max-w-20">Monthly</h3>
                  <p className="mt-2 text-base text-gray-500">Monthly plan access to our core features and functions. 0 limits on the amount of invoices.
                  </p>
                  <p className="mt-2 text-2xl font-extrabold text-indigo-600">£5.99/month</p>
                  <ul className="mt-4 text-base text-gray-500">
                    <li>Generate Invoices</li>
                    <li>Send Invoices</li>
                    <li>Create Products</li>
                    <li>Create Monthly Billing</li>
                    <li>View Stripe Transactions</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow-md border shadow-indigo-600">
                  <h3 className="text-lg font-medium text-indigo-500 rounded-full bg-indigo-200 text-center max-w-20">Yearly</h3>
                  <p className="mt-2 text-base text-gray-500">Pay for the year and enjoy. Invited to use Beta features before release.</p>
                  <p className="mt-2 text-2xl font-extrabold text-indigo-600">£54.98/year</p>
                  <ul className="mt-4 text-base text-gray-500">
                    <li>Generate Invoices</li>
                    <li>Send Invoices</li>
                    <li>Create Products</li>
                    <li>Create Monthly Billing</li>
                    <li>View Stripe Transactions</li>
                  </ul>
                </div>
                <div className="p-6 bg-white rounded-lg shadow-md border shadow-indigo-600">
                  <h3 className="text-lg font-medium text-indigo-500 rounded-full bg-indigo-200 text-center max-w-24">Enterprise</h3>
                  <p className="mt-2 text-base text-gray-500">For those who need a bespoke platform to manage all Stripe invoicing and payments.</p>
                  <p className="mt-2 text-2xl font-extrabold text-indigo-600">Enquire for Pricing</p>
                  <ul className="mt-4 text-base text-gray-500">
                    <li>Custom Setup</li>
                    <li>Bespoke Analytics</li>
                    <li>Advanced Invoice Management</li>
                    <li>Bespoke Features</li>
                    <li>Dedicated Customer Support</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Manage Your Invoices with Ease</span>
              <span className="block text-indigo-600">Generate, Send, and Track</span>
            </h2>
            <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <a href="/signup" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                  {ctaText}
                </a>
              </div>
            </div>
          </div>
        </div>

        <footer className="bg-gray-900 text-gray-300 py-12">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
      
      {/* Column 1: Company Info */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold text-white">Transacly.co</h3>
        <p className="text-gray-400">
          Simplifying Stripe Invoicing & Product Management for your business. Manage, track, and get paid faster with Transacly.
        </p>
        <div className="flex items-center space-x-4">
          {/* X Icon */}
          <a href="https://x.com/Transacly" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">
            <i className="fab fa-x-twitter text-2xl"></i>
          </a>
          {/* Product Hunt Badge */}
          <a href="https://www.producthunt.com/posts/transacly-co?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-transacly&#0045;co" target="_blank" rel="noopener noreferrer">
            <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=480454&theme=neutral" alt="Transacly.co - Stripe Invoicing & Product Management Platform | Product Hunt" className="h-12 w-auto" />
          </a>
        </div>
        <p className="text-gray-500 text-sm">&copy; 2024 Transacly - A proxitech company. All rights reserved.</p>
      </div>

      {/* Column 2: Product */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold text-white">Product</h3>
        <ul className="space-y-2">
          <li><a href="#features" className="hover:text-gray-100">Payment Methods</a></li>
          <li><a href="#invoicess" className="hover:text-gray-100">Invoices</a></li>
          <li><a href="#features" className="hover:text-gray-100">Recurring Billing</a></li>
          <li><a href="#features" className="hover:text-gray-100">Product Management</a></li>
          <li><a href="#features" className="hover:text-gray-100">Tax Compliance</a></li>
        </ul>
      </div>

      {/* Column 3: Use Cases */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold text-white">Use Cases</h3>
        <ul className="space-y-2">
          <li><a href="#" className="hover:text-gray-100">Freelancers</a></li>
          <li><a href="#" className="hover:text-gray-100">Small Businesses</a></li>
          <li><a href="#" className="hover:text-gray-100">Subscription Services</a></li>
          <li><a href="#" className="hover:text-gray-100">Digital Products</a></li>
        </ul>
      </div>

      {/* Column 4: Resources */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold text-white">Resources</h3>
        <ul className="space-y-2">
          <li><a href="https://transacly.statuspage.io/" className="hover:text-gray-100">Help Center</a></li>
          <li><a href="https://transacly.statuspage.io/" className="hover:text-gray-100">System Status</a></li>
        </ul>
      </div>

      {/* Column 5: Company */}
      <div className="space-y-4">
        <h3 className="text-lg font-bold text-white">Company</h3>
        <ul className="space-y-2">
          <li><a href="#faq" className="hover:text-gray-100">About</a></li>
          <li><a href="#pricing" className="hover:text-gray-100">Pricing</a></li>
          <li><a href="#faq" className="hover:text-gray-100">Privacy Policy</a></li>
          <li><a href="#faq" className="hover:text-gray-100">Terms of Service</a></li>
        </ul>
      </div>
    </div>

    {/* Footer Bottom Section */}
    <div className="mt-12 flex justify-between border-t border-gray-700 pt-8 text-sm text-gray-400">
      <p className="text-sm">&copy; 2024 Transacly - A proxitech company. All rights reserved.</p>
      <div className="flex space-x-4">
        <a href="https://www.producthunt.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">Product Hunt</a>
        <a href="https://nextjs.org" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">Next.js</a>
        <a href="https://stripe.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">Stripe</a>
        <a href="https://vercel.com" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">Vercel</a>
        <a href="https://reactjs.org" target="_blank" rel="noopener noreferrer" className="hover:text-gray-100">React</a>
      </div>
    </div>
  </div>
</footer>
      </main>
    </div>
  );
};

export default HomePage;