import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';

const generateCaptcha = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const Support = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    captcha: '',
  });
  const [status, setStatus] = useState('');
  const [captcha, setCaptcha] = useState(generateCaptcha());

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.captcha !== captcha) {
      setStatus('CAPTCHA verification failed. Please try again.');
      setCaptcha(generateCaptcha());
      return;
    }

    setStatus('Sending...');
    try {
      const response = await axios.post('https://api.transacly.co/api/support', formData);
      if (response.data.success) {
        setStatus('Message sent successfully!');
      } else {
        setStatus('Failed to send message.');
      }
    } catch (error) {
      setStatus('Error sending message.');
    }

    setCaptcha(generateCaptcha());
    setFormData({ ...formData, captcha: '' });
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <div className="min-h-screen">
      <NavigationBar handleLogout={handleLogout}/>

      {/* Main content area - shifted by 16rem to accommodate the sidebar */}
      <div className="pt-20 md:ml-64 p-6">
        {/* Sidebar */}
        {/* Main Content */}
        <div className="flex-1 lg:ml-8">
          <section id="what-is-transacly" className="mb-12">
            <h2 className="text-3xl font-bold text-gray-800">What is Transacly?</h2>
            <p className="mt-4 text-gray-600 leading-relaxed">
              Transacly is a cloud-based SaaS platform designed to make it easier for businesses and freelancers to
              manage invoicing and subscriptions through Stripe. Whether you're a small business owner or a freelancer,
              Transacly automates and simplifies tasks like billing, recurring payments, and invoice generation, all in
              one central platform.
            </p>
          </section>

          <section id="how-does-it-work" className="mb-12">
            <h2 className="text-3xl font-bold text-gray-800">How Does It Work?</h2>
            <p className="mt-4 text-gray-600 leading-relaxed">
              Transacly connects directly to your Stripe account. After securely inputting your Stripe API keys,
              Transacly syncs your products, customers, and transactions. You can create and manage products, generate
              invoices, set up recurring billing, and more—all from a user-friendly dashboard. Please note that Transacly is currently, in Beta version.
              If you find a bug please let us know to ensure we can sort it. We remain commited to ensuring we keep updating and building in new features, so stick around.
            </p>
          </section>

          <section id="what-can-i-do" className="mb-12">
            <h2 className="text-3xl font-bold text-gray-800">What Can I Do with It?</h2>
            <p className="mt-4 text-gray-600 leading-relaxed">
              With Transacly, you can:
            </p>
            <ul className="list-disc ml-8 mt-4 text-gray-600">
              <li>Generate professional, customizable invoices.</li>
              <li>Set up and manage recurring payments and subscriptions.</li>
              <li>Track your financial data with real-time reports.</li>
              <li>Automatically handle tax compliance and refunds through Stripe.</li>
            </ul>
          </section>

          <section id="multiple-stripe" className="mb-12">
            <h2 className="text-3xl font-bold text-gray-800">Multiple Stripe Accounts</h2>
            <p className="mt-4 text-gray-600 leading-relaxed">
              If you operate multiple businesses or projects, Transacly can only have one stripe account linked to one account with us. 
              Multiple stripe account management is something we are working towards.
            </p>
          </section>

          <section id="why-stripe-api" className="mb-12">
            <h2 className="text-3xl font-bold text-gray-800">Why Did You Need My Stripe API Keys?</h2>
            <p className="mt-4 text-gray-600 leading-relaxed">
            We use your Stripe API keys to securely link your Stripe account with Transacly. 
            This integration allows us to manage payments, invoices, and customer data on your behalf while ensuring that your sensitive information remains protected. 
            Your Stripe keys are encrypted within our database, making them inaccessible to unauthorized parties.
            If you need to update your keys, please submit a support request, and we will promptly assist you.
            </p>
          </section>

          <section id="delete-account" className="mb-12">
            <h2 className="text-3xl font-bold text-gray-800">How Can I Delete My Account?</h2>
            <p className="mt-4 text-gray-600 leading-relaxed">
            If you no longer wish to use Transacly, please contact our support team. We will promptly process your account deletion request and ensure that your data is securely removed from our systems. 
            If you prefer to cancel your plan, we can assist with that as well, which will automatically initiate the account deletion process. This ensures we do not retain any unnecessary data. 
            Your data belongs to you, and we will only keep what is legally required. Please reach out to us, and we may be able to resolve any issues that led to your decision to leave.
            </p>
          </section>

          {/* Contact Support Form */}
          <section id="contact-support" className="bg-white p-8 rounded shadow-md">
            <h2 className="text-3xl mb-4 font-bold text-gray-800 text-center">Contact Support</h2>
            <p className="text-center text-gray-500 mb-8">Send us a message, and we’ll get back to you as soon as possible.</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-6">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full border rounded-lg py-2 px-4 text-gray-700 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full border rounded-lg py-2 px-4 text-gray-700 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="message">
                  Message
                </label>
                <textarea
                  name="message"
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="w-full border                   rounded-lg py-2 px-4 text-gray-700 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                ></textarea>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 font-medium mb-2" htmlFor="captcha">
                  Enter CAPTCHA: {captcha}
                </label>
                <input
                  type="text"
                  name="captcha"
                  id="captcha"
                  value={formData.captcha}
                  onChange={handleChange}
                  required
                  className="w-full border rounded-lg py-2 px-4 text-gray-700 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                >
                  Submit
                </button>
              </div>
            </form>
            {status && (
              <p className={`mt-4 text-center ${status.includes('successfully') ? 'text-green-500' : 'text-red-500'}`}>
                {status}
              </p>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default Support;