import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51PM1vfEwD3yjIzQ3rXtSdWFRHkBKBbBrSFzVKw79ismz0CrpNCAltW48qDLmWvmmHh3kSP7q9l3tcH0shD8El41H00hpL4Qz1v'); // Replace with your actual Stripe public key

function Plans() {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  const handlePlanPurchase = async (plan) => {
    setLoading(true);
    setError('');
    const token = localStorage.getItem('token');

    try {
      const stripe = await stripePromise;
      const response = await axios.post(
        'https://api.transacly.co/create-checkout-session',
        { plan },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const session = response.data;
      const result = await stripe.redirectToCheckout({ sessionId: session.sessionId });

      if (result.error) {
        setError(result.error.message);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setError('Error creating checkout session. Please try again.');
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="relative bg-white p-8 rounded-lg shadow-xl w-full max-w-2xl">
        <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Choose Your Plan to access Transacly.</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="flex flex-col md:flex-row justify-between space-y-6 md:space-y-0 md:space-x-6">
          <div className="border p-6 rounded-lg shadow-lg flex-1 bg-gray-50 hover:bg-gray-100 transition transform hover:scale-105">
            <h3 className="text-2xl font-semibold mb-4 text-blue-600 text-center">Monthly Plan</h3>
            <p className="text-lg font-medium mb-4 text-gray-700">£5.99 per month</p>
            <ul className="mb-6 list-disc list-inside text-gray-700">
              <li>Unlimited Invoice Generation</li>
              <li>Access to all future updates and releases</li>
              <li>Access to new software released by Transactly</li>
              <li>Beta Access to the email send function</li>
            </ul>
            <button
              onClick={() => handlePlanPurchase('monthly')}
              className="w-full bg-white text-blue-500 py-3 rounded-lg border-blue-600 border-2 font-semibold hover:bg-blue-700 hover:text-white transition"
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Buy Monthly Plan'}
            </button>
          </div>
          <div className="border p-6 rounded-lg shadow-lg flex-1 bg-gray-50 hover:bg-gray-100 transition transform hover:scale-105">
            <h3 className="text-2xl font-semibold mb-4 text-blue-600 text-center">Yearly Plan</h3>
            <p className="text-lg font-medium mb-4 text-gray-700">
              <span className="line-through">$54.98</span> <span className="text-green-600"> 50% OFF (£27.49)</span>
            </p>
            <ul className="mb-6 list-disc list-inside text-gray-700">
              <li>Unlimited Invoice Generation</li>
              <li>Access to all future updates and releases</li>
              <li>Access to new software released by Transactly</li>
              <li>Beta Access to the email send function</li>
            </ul>
            <button
              onClick={() => handlePlanPurchase('yearly')}
              className="w-full bg-white text-blue-500 py-3 rounded-lg border-blue-600 border-2 font-semibold hover:bg-blue-700 hover:text-white transition"
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Buy Yearly Plan (50% Off)'}
            </button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .bg-gray-100 {
          background: linear-gradient(135deg, #cecdd1 25%, transparent 25%) -50px 0,
                      linear-gradient(225deg, #cecdd1 25%, transparent 25%) -50px 0,
                      linear-gradient(315deg, #cecdd1 25%, transparent 25%),
                      linear-gradient(45deg, #cecdd1 25%, transparent 25%);
          background-size: 100px 100px;
          background-color: #e1e1e3;
        }
      `}</style>
    </div>
  );
}

export default Plans;