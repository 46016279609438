import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import InvoiceDocument from './InvoiceDocument';
import { loadStripe } from '@stripe/stripe-js';

const generateCaptcha = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const InvoiceDetail = () => {
  const { invoiceUURL } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [status, setStatus] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await axios.get(`https://api.transacly.co/api/invoice/${invoiceUURL}`);
        setInvoice(response.data);
        setIsLoggedIn(!!localStorage.getItem('token'));
      } catch (err) {
        console.error('Error fetching invoice:', err);
        setError('Error fetching invoice');
      }
    };

    fetchInvoice();
  }, [invoiceUURL]);

  const handlePayNow = async () => {
    try {
      const response = await axios.post(
        'https://api.transacly.co/api/create-checkout-sessions',
        { invoiceId: invoice.id } // Ensure the correct invoice ID is passed
      );

      const { sessionId, stripePublicKey } = response.data;

      const stripe = await loadStripe(stripePublicKey);

      if (!stripe) {
        throw new Error('Stripe.js has not yet loaded.');
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (error) {
        console.error('Error processing payment:', error);
        alert('Error processing payment');
      }
    } catch (err) {
      console.error('Error processing payment:', err);
      alert('Error processing payment');
    }
  };

  const handleSendInvoice = async () => {
    if (captchaInput !== captcha) {
      setStatus('CAPTCHA verification failed. Please try again.');
      setCaptcha(generateCaptcha());
      return;
    }

    setStatus('Sending invoice...');

    try {
      const response = await axios.post('https://api.transacly.co/api/send-invoice-url', {
        invoiceId: invoice.id,
        email: invoice.customer_email,
      });

      if (response.data.success) {
        setStatus('Invoice sent successfully!');
      } else {
        setStatus('Failed to send invoice.');
      }
    } catch (error) {
      console.error('Error sending invoice:', error);
      setStatus('Error sending invoice.');
    }

    setModalIsOpen(false);
  };

  const openModal = () => {
    setCaptcha(generateCaptcha());
    setCaptchaInput('');
    setStatus('');
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  if (error) {
    return <div>{error}</div>;
  }

  if (!invoice) {
    return <div>Loading...</div>;
  }

  const loggedInUserId = parseInt(localStorage.getItem('userId'), 10);

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-100">
      <div className="max-w-6xl w-full p-6">
        <div className="bg-white p-6 rounded shadow-md mb-4">
          <h3 className="text-xl mb-4 text-center">Invoice Details</h3>
          <div className="flex justify-between mb-4">
            {isLoggedIn && (
              <button
                onClick={() => navigate('/invoiceList')}
                className="bg-gray-500 text-white p-3 rounded"
              >
                Back to Invoices
              </button>
            )}
            <PDFDownloadLink
              document={<InvoiceDocument invoice={invoice} />}
              fileName={`invoice_${invoice.id}.pdf`}
              className="bg-blue-500 text-white p-3 rounded"
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download PDF'
              }
            </PDFDownloadLink>
          </div>
          <PDFViewer width="100%" height="600">
            <InvoiceDocument invoice={invoice} />
          </PDFViewer>
          {invoice.status === 'UNPAID' && !invoice.isArchived && (
            <button
              onClick={handlePayNow}
              className="bg-green-500 text-white w-full p-3 rounded mt-4"
            >
              Pay Now
            </button>
          )}
          {invoice.isArchived && (
            <div className="text-red-500 text-center mt-4">
              This is an archived invoice and cannot be paid.
            </div>
          )}
          {isLoggedIn && (
            <button
              onClick={openModal}
              className="bg-blue-500 text-white w-full p-3 rounded mt-4 flex items-center justify-center"
            >
              <span className="material-icons">Send Invoice</span>
            </button>
          )}
        </div>
      </div>

      {modalIsOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md">
            <h2 className="text-2xl mb-4 text-center">CAPTCHA Verification</h2>
            <p className="mb-4 text-center">Please enter the CAPTCHA to send the invoice.</p>
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="captcha">
              CAPTCHA: {captcha}
            </label>
            <input
              type="text"
              name="captcha"
              id="captcha"
              value={captchaInput}
              onChange={(e) => setCaptchaInput(e.target.value)}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <button
              onClick={handleSendInvoice}
              className="bg-blue-500 text-white w-full p-3 rounded mt-4 flex items-center justify-center"
            >
            Send Invoice
            </button>
            {status && <p className="mt-4 text-center text-red-500">{status}</p>}
            <button
              onClick={closeModal}
              className="bg-gray-500 text-white w-full p-3 rounded mt-4"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceDetail;