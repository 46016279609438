import React, { useEffect, useState } from 'react';
import axios from 'axios';

function DashMRR({ isDarkMode }) {
  const [totalActiveSubscriptions, setTotalActiveSubscriptions] = useState(0);
  const [monthlyRecurringRevenue, setMonthlyRecurringRevenue] = useState(0);
  const [yearlyRecurringRevenue, setYearlyRecurringRevenue] = useState(0);

  useEffect(() => {
    const fetchRecurringRevenue = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('https://api.transacly.co/api/recurring-transactions', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const { subscriptions, totalMRR } = response.data;
          setTotalActiveSubscriptions(subscriptions.length);
          setMonthlyRecurringRevenue(totalMRR);
          setYearlyRecurringRevenue(totalMRR * 12); // Calculate yearly revenue from MRR
        } catch (error) {
          console.log('Error fetching recurring revenue:', error);
        }
      }
    };

    fetchRecurringRevenue();
  }, []);

  return (
    <div className={`p-6 rounded shadow-md mb-4 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}>
      <h3 className="text-xl mb-4 font-semibold">Recurring Revenue Overview</h3>
      {/* Adjust grid layout for mobile and desktop screens */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        <div className={`p-4 rounded shadow-md text-center ${isDarkMode ? 'bg-gray-700 border-none' : 'bg-white'} border border-gray-200`}>
          <h4 className="text-lg">Active Subscriptions</h4>
          <p className="text-2xl font-semibold">{totalActiveSubscriptions}</p>
        </div>
        <div className={`p-4 rounded shadow-md text-center ${isDarkMode ? 'bg-green-700 border-none' : 'bg-green-100 text-green-800'} border border-green-300`}>
          <h4 className="text-lg">Monthly Recurring Revenue</h4>
          <p className="text-2xl font-semibold">${monthlyRecurringRevenue}</p>
        </div>
        <div className={`p-4 rounded shadow-md text-center ${isDarkMode ? 'bg-green-700 border-none' : 'bg-green-100 text-green-800'} border border-green-300`}>
          <h4 className="text-lg">Yearly Recurring Revenue</h4>
          <p className="text-2xl font-semibold">${yearlyRecurringRevenue}</p>
        </div>
      </div>
    </div>
  );
}

export default DashMRR;