import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import ReactPaginate from 'react-paginate';
import { FaEye, FaEyeSlash, FaCog } from 'react-icons/fa';

const InvoiceList = () => {
  const [invoices, setInvoices] = useState([]);
  const [paidCount, setPaidCount] = useState(0);
  const [unpaidCount, setUnpaidCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentInvoices, setCurrentInvoices] = useState([]);
  const [emailVisibility, setEmailVisibility] = useState({});
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showCaptchaModal, setShowCaptchaModal] = useState(false);
  const [captchaCode, setCaptchaCode] = useState('');
  const [generatedCaptcha, setGeneratedCaptcha] = useState('');
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [captchaError, setCaptchaError] = useState('');
  const [dropdowns, setDropdowns] = useState({});
  const invoicesPerPage = 15;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInvoices = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://api.transacly.co/api/invoices', {
          headers: { Authorization: `Bearer ${token}` },
        });
        const invoices = response.data;
        setInvoices(invoices);
        setPaidCount(invoices.filter(invoice => invoice.status === 'PAID').length);
        setUnpaidCount(invoices.filter(invoice => invoice.status === 'UNPAID').length);
        setCurrentInvoices(invoices.slice(0, invoicesPerPage));
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * invoicesPerPage;
    setCurrentInvoices(invoices.slice(offset, offset + invoicesPerPage));
    setCurrentPage(selectedPage);
  };

  // Function to handle viewing the invoice
  const viewInvoice = (invoiceUURL) => {
    navigate(`/invoice/${invoiceUURL}`);
  };

  // Function to toggle email visibility
  const toggleEmailVisibility = (invoiceId) => {
    setEmailVisibility(prevState => ({
      ...prevState,
      [invoiceId]: !prevState[invoiceId]
    }));
  };

  // Function to toggle dropdown visibility
  const toggleDropdown = (invoiceId) => {
    setDropdowns(prevState => ({
      ...prevState,
      [invoiceId]: !prevState[invoiceId],
    }));
  };

  const openCaptchaModal = (invoiceId) => {
    setSelectedInvoiceId(invoiceId);
    const randomCaptcha = Math.random().toString(36).substring(2, 7); // Generate a random 5-character string
    setGeneratedCaptcha(randomCaptcha); // Set the generated captcha
    setCaptchaCode(''); // Clear the input field for the captcha
    setCaptchaError('');
    setShowCaptchaModal(true);
  };

  const handleCaptchaSubmit = async () => {
    if (captchaCode !== generatedCaptcha) { // Validate the entered captcha against the generated one
      setCaptchaError('Invalid Captcha code');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://api.transacly.co/api/send-invoice-url', {
        invoiceId: selectedInvoiceId,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      alert('Invoice has been resent successfully');
      setShowCaptchaModal(false);
    } catch (error) {
      console.error('Error resending invoice:', error);
      alert('Error resending invoice');
    }
  };

  return (
    <div className="min-h-screen">
      <NavigationBar handleLogout={handleLogout}/>

      {/* Main content area - shifted by 16rem to accommodate the sidebar */}
      <div className="pt-20 md:ml-64 p-6">
        <div className={`p-6 rounded shadow-md mb-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <h3 className="text-xl mb-4 font-semibold">Invoice Summary</h3>
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className={`p-4 rounded shadow-md text-center ${isDarkMode ? 'bg-blue-900 text-blue-200' : 'bg-blue-100 text-blue-900'}`}>
              <h4 className="text-lg">Total Invoices</h4>
              <p className="text-2xl">{invoices.length}</p>
            </div>
            <div className={`p-4 rounded shadow-md text-center ${isDarkMode ? 'bg-green-900 text-green-200' : 'bg-green-100 text-green-900'}`}>
              <h4 className="text-lg">Paid Invoices</h4>
              <p className="text-2xl">{paidCount}</p>
            </div>
            <div className={`p-4 rounded shadow-md text-center ${isDarkMode ? 'bg-red-900 text-red-200' : 'bg-red-100 text-red-900'}`}>
              <h4 className="text-lg">Unpaid Invoices</h4>
              <p className="text-2xl">{unpaidCount}</p>
            </div>
          </div>
          <table className={`min-w-full border ${isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-200'}`}>
            <thead>
              <tr>
                <th className={`py-2 px-4 border-b text-left ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>Invoice UID</th>
                <th className={`py-2 px-4 border-b text-left ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>Customer Email</th>
                <th className={`py-2 px-4 border-b text-left ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>Status</th>
                <th className={`py-2 px-4 border-b text-left ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>Created Date</th>
                <th className={`py-2 px-4 border-b text-left ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}></th> {/* For dropdown menu */}
              </tr>
            </thead>
            <tbody>
              {currentInvoices.map((invoice) => (
                <tr key={invoice.uurl} className={`border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>
                  <td className="py-2 px-4">{invoice.uurl}</td>
                  <td className="py-2 px-4">
                    <div className="flex items-center">
                      <span>{emailVisibility[invoice.uurl] ? invoice.customer_email : '•••••••••••'}</span>
                      <button
                        onClick={() => toggleEmailVisibility(invoice.uurl)}
                        className="ml-2 focus:outline-none"
                      >
                        {emailVisibility[invoice.uurl] ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                  </td>
                  <td className={`inline-flex items-center justify-center text-xs font-medium px-2.5 py-0.5 mt-5 rounded-full ${invoice.status === 'PAID' ? (isDarkMode ? 'bg-green-600 text-green-200' : 'bg-green-300 text-green-600') : (isDarkMode ? 'bg-red-600 text-red-200' : 'bg-red-300 text-red-600')}`}>
                    {invoice.status}
                  </td>
                  <td className="py-2 px-4">{new Date(invoice.created_at).toLocaleDateString()}</td>
                  <td className="py-2 px-4">
                    <div className="relative">
                      <button
                        onClick={() => toggleDropdown(invoice.uurl)}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                      >
                        <FaCog />
                      </button>
                      {dropdowns[invoice.uurl] && (
                        <div className="absolute right-0 mt-2 w-32 bg-white rounded-lg shadow-lg z-10">
                          <button
                            onClick={() => viewInvoice(invoice.uurl)}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                          >
                            View Invoice
                          </button>
                          <button
                            onClick={() => openCaptchaModal(invoice.id)}
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-200"
                          >
                            Resend
                          </button>
                          <button
                            className="block w-full text-left px-4 py-2 text-sm text-red-700 hover:bg-gray-200"
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex justify-between items-center mt-4">
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={Math.ceil(invoices.length / invoicesPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination flex justify-center'}
                pageClassName={'page-item'}
                pageLinkClassName={`page-link px-3 py-1 rounded border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
                previousClassName={'page-item'}
                previousLinkClassName={`page-link px-3 py-1 rounded border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
                nextClassName={'page-item'}
                nextLinkClassName={`page-link px-3 py-1 rounded border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
                activeClassName={'active'}
                activeLinkClassName={'bg-blue-500 text-white'}
              />
            </div>
          </div>
        </div>
  
{/* Captcha Modal */}
{showCaptchaModal && (
  <div className="fixed z-50 inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
    <div className={`bg-white p-6 rounded shadow-md max-w-md w-full ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-black'}`}>
      <h4 className="text-xl mb-4 text-center font-semibold">Resend Invoice</h4>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Enter Captcha Code</label>
        <div className="flex justify-center items-center mb-2">
          <span className="bg-gray-200 p-2 rounded font-mono text-lg tracking-widest">
            {generatedCaptcha} {/* Display the generated captcha */}
          </span>
        </div>
        <input
          type="text"
          value={captchaCode}
          onChange={(e) => setCaptchaCode(e.target.value)}
          className={`border p-2 rounded w-full ${isDarkMode ? 'bg-gray-700 border-gray-600 text-white' : 'border-gray-300'}`}
        />
        {captchaError && <p className="text-red-500 text-sm mt-2">{captchaError}</p>}
      </div>
      <div className="flex justify-end">
        <button
          onClick={handleCaptchaSubmit}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Resend Invoice
        </button>
        <button
          onClick={() => setShowCaptchaModal(false)}
          className="bg-gray-500 text-white px-4 py-2 ml-2 rounded"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}
      </div>
    );
  };
  
  export default InvoiceList;