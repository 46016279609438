import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import NavigationBar from './NavigationBar';
import DashGraph from '../Modules/dash-graph';
import TransactionsTable from '../Modules/dash-transactions';
import DashMRR from '../Modules/dash-MRR';
import DashStatements from '../Modules/dash-Statements'; 
import moment from 'moment';

function Dashboard() {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [currentTransactions, setCurrentTransactions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [dateFilter, setDateFilter] = useState('All');
  const [pageCount, setPageCount] = useState(0);
  const transactionsPerPage = 15;
  const [dailyTotals, setDailyTotals] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState(false);
  
  // Create separate loading states for graph and transactions
  const [loadingGraph, setLoadingGraph] = useState(true);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  
  const [timeRange, setTimeRange] = useState(7); 

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          setLoadingGraph(true);
          setLoadingTransactions(true);

          // Fetch transactions
          const transactionsResponse = await axios.get('https://api.transacly.co/api/transactions', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const sortedTransactions = transactionsResponse.data.sort((a, b) => new Date(b.date) - new Date(a.date));
          setTransactions(sortedTransactions);
          setFilteredTransactions(sortedTransactions);
          setPageCount(Math.ceil(sortedTransactions.length / transactionsPerPage));
          setCurrentTransactions(sortedTransactions.slice(0, transactionsPerPage));
          aggregateDailyTotals(sortedTransactions, timeRange);

          // Set loading states to false once data is fetched
          setLoadingGraph(false);
          setLoadingTransactions(false);
        } catch (error) {
          console.log('Error fetching transactions:', error);
          navigate('/login');
        }
      }
    };

    fetchData();
  }, [navigate, timeRange]);

  const aggregateDailyTotals = (transactions, range) => {
    const totals = {};
    const today = moment().startOf('day');

    for (let i = 0; i < range; i++) {
      const date = today.clone().subtract(i, 'days').format('YYYY-MM-DD');
      totals[date] = 0;
    }

    transactions.forEach((transaction) => {
      const date = moment(transaction.date).format('YYYY-MM-DD');
      if (totals.hasOwnProperty(date)) {
        totals[date] += transaction.amount;
      }
    });

    const dailyTotalsArray = Object.keys(totals).map((date) => ({
      date,
      amount: totals[date],
    }));

    setDailyTotals(dailyTotalsArray.reverse());
  };

  const applyFilters = () => {
    let filtered = transactions;

    if (searchTerm) {
      filtered = filtered.filter((transaction) =>
        transaction.id.includes(searchTerm) ||
        (transaction.description && transaction.description.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    if (statusFilter !== 'All') {
      filtered = filtered.filter((transaction) => transaction.status === statusFilter);
    }

    const now = moment();
    if (dateFilter === 'Year') {
      filtered = filtered.filter((transaction) => moment(transaction.date).isSame(now, 'year'));
    } else if (dateFilter === 'Month') {
      filtered = filtered.filter((transaction) => moment(transaction.date).isSame(now, 'month'));
    } else if (dateFilter === 'Week') {
      filtered = filtered.filter((transaction) => moment(transaction.date).isSame(now, 'week'));
    }

    setFilteredTransactions(filtered);
    setPageCount(Math.ceil(filtered.length / transactionsPerPage));
    setCurrentTransactions(filtered.slice(0, transactionsPerPage));
  };

  useEffect(() => {
    applyFilters();
  }, [searchTerm, statusFilter, dateFilter, transactions]);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    const offset = selectedPage * transactionsPerPage;
    setCurrentTransactions(filteredTransactions.slice(offset, offset + transactionsPerPage));
  };

  return (
    <div className="min-h-screen">
      <NavigationBar handleLogout={handleLogout}/>

      {/* Main content area - shifted by 16rem to accommodate the sidebar */}
      <div className="pt-20 md:ml-64 p-6">
        <div className={`p-6 rounded shadow-md mb-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="flex justify-between items-center mb-4 flex-col sm:flex-row">
            <h3 className="text-xl font-semibold mb-4 sm:mb-0">Sales Overview</h3>

            <div className="flex flex-row">
              <button
                className={`mx-2 p-2 rounded border  ${timeRange === 7 ? 'bg-blue-500 text-blue-700' : 'bg-white border-gray-300 text-black'}`}
                onClick={() => setTimeRange(7)}
              >
                Last 7 Days
              </button>
              <button
                className={`mx-2 p-2 rounded border ${timeRange === 30 ? 'bg-blue-500 text-blue-700' : 'bg-white border-gray-300 text-black'}`}
                onClick={() => setTimeRange(30)}
              >
                Last 30 Days
              </button>
              <button
                className={`mx-2 p-2 rounded border ${timeRange === 90 ? 'bg-blue-500 text-blue-700' : 'bg-white border-gray-300 text-black'}`}
                onClick={() => setTimeRange(90)}
              >
                Last 3 Months
              </button>
            </div>
          </div>
          
          {/* Conditionally Render Loading Spinner for Graph */}
          {loadingGraph ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          ) : (
            <DashGraph dailyTotals={dailyTotals} isDarkMode={isDarkMode} />
          )}
        </div>

        <DashMRR isDarkMode={isDarkMode} />

        <div className={`p-6 rounded shadow-md mb-4 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="flex flex-col sm:flex-row justify-between items-center mb-4 gap-4">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={`p-2 border ${isDarkMode ? 'border-gray-600 bg-gray-700 text-white' : 'border-gray-300 bg-white text-black'} rounded`}
              placeholder="Search transactions..."
            />

            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className={`p-2 border ${isDarkMode ? 'border-gray-600 bg-gray-700 text-white' : 'border-gray-300 bg-white text-black'} rounded`}
            >
              <option value="All">All Statuses</option>
              <option value="succeeded">Succeeded</option>
              <option value="incomplete">Incomplete</option>
              <option value="refunded">Refunded</option>
            </select>

            <select
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className={`p-2 border ${isDarkMode ? 'border-gray-600 bg-gray-700 text-white' : 'border-gray-300 bg-white text-black'} rounded`}
            >
              <option value="All">All Time</option>
              <option value="Week">This Week</option>
              <option value="Month">This Month</option>
              <option value="Year">This Year</option>
            </select>

            <DashStatements />
          </div>

          {/* Conditionally Render Loading Spinner for Transactions Table */}
            <TransactionsTable currentTransactions={currentTransactions} isDarkMode={isDarkMode} />
          {/* Pagination */}
          <div className="flex flex-col sm:flex-row justify-between items-center mt-4 gap-4">
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'pagination flex justify-center'}
              pageClassName={'page-item'}
              pageLinkClassName={`page-link px-3 py-1 rounded border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
              previousClassName={'page-item'}
              previousLinkClassName={`page-link px-3 py-1 rounded border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
              nextClassName={'page-item'}
              nextLinkClassName={`page-link px-3 py-1 rounded border ${isDarkMode ? 'border-gray-600' : 'border-gray-300'}`}
              activeClassName={'active'}
              activeLinkClassName={'bg-blue-500 text-white'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;