import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

function Signup() {
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [validated, setValidated] = useState(false); // Validation success state
  const [loginInfo, setLoginInfo] = useState({ name: '', email: '', password: '' });
  const [passwordStrength, setPasswordStrength] = useState('');
  const [stripeKeys, setStripeKeys] = useState({ publicKey: '', privateKey: '' });
  const [companyInfo, setCompanyInfo] = useState({
    company_name: '',
    address_line1: '',
    address_line2: '',
    city: '',
    state: '',
    postal_code: '',
    country: '',
    vat_number: ''
  });
  const [agreeToPolicies, setAgreeToPolicies] = useState(false);
  const [signupEnabled, setSignupEnabled] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [showPublicTooltip, setShowPublicTooltip] = useState(false);
  const [showPrivateTooltip, setShowPrivateTooltip] = useState(false);

  useEffect(() => {
    const fetchSignupStatus = async () => {
      try {
        const response = await axios.get('https://api.transacly.co/signup-status');
        setSignupEnabled(response.data.signupEnabled);
      } catch (error) {
        console.error('Error fetching signup status:', error);
      }
    };

    fetchSignupStatus();
  }, []);

  const sanitizeInput = (input) => {
    const element = document.createElement('div');
    element.innerText = input;
    return element.innerHTML;
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setLoginInfo({ ...loginInfo, password });

    const strength = evaluatePasswordStrength(password);
    setPasswordStrength(strength);
  };

  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/[0-9]/.test(password)) strength++;
    if (/[^A-Za-z0-9]/.test(password)) strength++;
    return strength;
  };

  const handleNextStep = async () => {
    if (step === 1) {
      // Validate loginInfo
      if (!loginInfo.name || !loginInfo.email || !loginInfo.password) {
        setError('Please fill in all fields marked with *.');
        return;
      }
      if (passwordStrength < 4) {
        setError('Password is too weak. Please use a stronger password.');
        return;
      }
      // Sanitize inputs
      loginInfo.name = sanitizeInput(loginInfo.name);
      loginInfo.email = sanitizeInput(loginInfo.email);
    } else if (step === 2) {
      // Validate Stripe keys
      if (!stripeKeys.publicKey || !stripeKeys.privateKey) {
        setError('Please enter both Stripe keys.');
        return;
      }
      const isTestKey = stripeKeys.privateKey.startsWith('sk_test_');
      if (isTestKey) {
        setError('Test keys are not allowed. Please use live Stripe keys.');
        return;
      }

      // Set loading to true before starting verification
      setLoading(true);
      setError('');

      try {
        const response = await axios.post('https://api.transacly.co/validate-stripe-keys', stripeKeys);
        if (response.status === 200) {
          setValidated(true); // Keys are validated
          setTimeout(() => {
            setLoading(false);
            setStep(step + 1); // Proceed to the next step
          }, 1500); // Delay to show success checkmark before moving to the next step
        }
      } catch (error) {
        setError('Invalid Stripe keys. Please check your keys and try again.');
        setLoading(false); // Stop loading if there's an error
        return;
      }
    } else if (step === 3) {
      // Validate companyInfo
      if (!companyInfo.company_name || !companyInfo.address_line1 || !companyInfo.city ||
          !companyInfo.state || !companyInfo.postal_code || !companyInfo.country) {
        setError('Please fill in all required fields.');
        return;
      }
      // Sanitize inputs
      for (let key in companyInfo) {
        companyInfo[key] = sanitizeInput(companyInfo[key]);
      }
    }
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate final step fields before submission
    if (!companyInfo.company_name || !companyInfo.address_line1 || !companyInfo.city ||
        !companyInfo.state || !companyInfo.postal_code || !companyInfo.country) {
      setError('Please fill in all required fields.');
      return;
    }

    if (!agreeToPolicies) {
      setError('You must agree to the Terms of Service, Privacy Policy, and Data Sharing Policies.');
      return;
    }

    try {
      // Step 1: Create user
      const signupResponse = await axios.post('https://api.transacly.co/signup', loginInfo);
      if (signupResponse.status === 201) {
        const userId = signupResponse.data.userId;
        const token = signupResponse.data.token;
        setToken(token); // Store the token

        try {
          // Step 2: Save Stripe API keys
          const stripeKeysResponse = await axios.post('https://api.transacly.co/stripe-keys', { userId, ...stripeKeys }, {
            headers: {
              'Authorization': `Bearer ${token}` // Use the token
            }
          });

          if (stripeKeysResponse.status === 201) {
            try {
              // Step 3: Save company information
              await axios.post('https://api.transacly.co/api/company-info', { userId, ...companyInfo }, {
                headers: {
                  'Authorization': `Bearer ${token}` // Use the token
                }
              });

              navigate('/login');
            } catch (companyInfoError) {
              console.error('Error saving company information:', companyInfoError);
              setError('Error saving company information');
            }
          } else {
            setError('Error saving Stripe keys');
          }
        } catch (stripeKeysError) {
          console.error('Error saving Stripe keys:', stripeKeysError);
          setError('Error saving Stripe keys');
        }
      } else {
        setError('Error creating user');
      }
    } catch (signupError) {
      console.error('Error creating user:', signupError);
      if (signupError.response && signupError.response.data && signupError.response.data.message === 'Email already exists') {
        setError('Email already exists. Please use a different email.');
      } else {
        setError('Error creating user');
      }
    }
  };

  const getPasswordStrengthMessage = () => {
    switch (passwordStrength) {
      case 1:
        return 'Very weak';
      case 2:
        return 'Weak';
      case 3:
        return 'Moderate';
      case 4:
        return 'Strong';
      case 5:
        return 'Very strong';
      default:
        return '';
    }
  };

  if (!signupEnabled) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-white p-6 rounded shadow-md w-full max-w-lg text-center">
          <h2 className="text-2xl mb-4">Signup to Transacly</h2>
          <p className="text-red-500 mb-4">
            Registration is temporarily disabled for now. If you have an account, please <a href="/login" className="text-blue-500">login here</a>.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full max-w-lg">
        <h2 className="text-2xl mb-4 text-center">Signup to Transacly</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <p className="mb-4 text-sm text-gray-600">Fields marked with <span className="text-red-500">*</span> are required.</p>
        <div className="mb-4">
          <div className="relative pt-1">
            <div className="flex mb-2 items-center justify-between">
              <div>
                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                  Step {step} of 3
                </span>
              </div>
              <div className="text-right">
                <span className="text-xs font-semibold inline-block text-green-600">
                  {Math.round((step / 3) * 100)}%
                </span>
              </div>
            </div>
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
              <div
                style={{ width: `${(step / 3) * 100}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500">
              </div>
            </div>
          </div>
        </div>

        <SwitchTransition>
          <CSSTransition
            key={step}
            timeout={300}
            classNames={{
              enter: 'animate-slideInRight',
              exit: 'animate-slideOutLeft',
            }}
          >
            <div>
              {step === 1 && (
                <div className="step step1">
                  <input
                    type="text"
                    value={loginInfo.name}
                    onChange={(e) => setLoginInfo({ ...loginInfo, name: e.target.value })}
                    placeholder="Name *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="name"
                  />
                  <input
                    type="email"
                    value={loginInfo.email}
                    onChange={(e) => setLoginInfo({ ...loginInfo, email: e.target.value })}
                    placeholder="Email *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="email"
                  />
                  <input
                    type="password"
                    value={loginInfo.password}
                    onChange={handlePasswordChange}
                    placeholder="Password *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="new-password"
                  />
                  <p className="text-sm text-gray-600 mb-4">
                    Password strength: {getPasswordStrengthMessage()}
                  </p>
                  <div className="flex justify-between">
                    <button type="button" className="bg-gray-500 text-white p-2 rounded" disabled>Back</button>
                    <button type="button" onClick={handleNextStep} className="bg-blue-500 text-white p-2 rounded">Next</button>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="step step2">
                  {loading ? (
                    <div className="flex flex-col items-center">
                      <svg className="animate-spin h-8 w-8 text-blue-500 mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                      </svg>
                      {validated && <span className="text-green-500 font-semibold">Stripe keys validated!</span>}
                    </div>
                  ) : (
                    <div>
                      {/* Public Key Field */}
                      <div className="relative flex items-center">
                        <input
                          type="text"
                          value={stripeKeys.publicKey}
                          onChange={(e) => setStripeKeys({ ...stripeKeys, publicKey: e.target.value })}
                          placeholder="Stripe Public Key *"
                          className="border p-2 mb-4 w-full"
                          autoComplete="cc-number"
                        />
                        {/* Info Icon for Public Key */}
                        <div
                          className="ml-2 cursor-pointer relative"
                          onMouseEnter={() => setShowPublicTooltip(true)}
                          onMouseLeave={() => setShowPublicTooltip(false)}
                        >
                          <div className="flex justify-center items-center w-4 h-4 bg-blue-300 text-white rounded-full text-xs font-bold">
                            i
                          </div>
                          {showPublicTooltip && (
                            <div className="absolute left-0 bottom-full mb-2 w-64 bg-gray-700 text-white text-sm p-2 rounded shadow-lg">
                              We need your Stripe API keys to create, manage, and automate payments through the Stripe API.
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Private Key Field */}
                      <div className="relative flex items-center">
                        <input
                          type="text"
                          value={stripeKeys.privateKey}
                          onChange={(e) => setStripeKeys({ ...stripeKeys, privateKey: e.target.value })}
                          placeholder="Stripe Private Key *"
                          className="border p-2 mb-4 w-full"
                          autoComplete="cc-number"
                        />
                        {/* Info Icon for Private Key */}
                        <div
                          className="ml-2 cursor-pointer relative"
                          onMouseEnter={() => setShowPrivateTooltip(true)}
                          onMouseLeave={() => setShowPrivateTooltip(false)}
                        >
                          <div className="flex justify-center items-center w-4 h-4 bg-blue-300 text-white rounded-full text-xs font-bold">
                            i
                          </div>
                          {showPrivateTooltip && (
                            <div className="absolute left-0 bottom-full mb-2 w-64 bg-gray-700 text-white text-sm p-2 rounded shadow-lg">
                              Your Stripe private key is required to authenticate API requests securely.
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex justify-between">
                        <button type="button" onClick={handlePrevStep} className="bg-gray-500 text-white p-2 rounded">Back</button>
                        <button type="button" onClick={handleNextStep} className="bg-blue-500 text-white p-2 rounded">Validate</button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {step === 3 && (
                <div className="step step3">
                  <input
                    type="text"
                    value={companyInfo.company_name}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, company_name: e.target.value })}
                    placeholder="Company Name *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="organization"
                  />
                  <input
                    type="text"
                    value={companyInfo.address_line1}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, address_line1: e.target.value })}
                    placeholder="House Number and Street Name *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="address-line1"
                  />
                  <input
                    type="text"
                    value={companyInfo.address_line2}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, address_line2: e.target.value })}
                    placeholder="Address Line 2"
                    className="border p-2 mb-4 w-full"
                    autoComplete="address-line2"
                  />
                  <input
                    type="text"
                    value={companyInfo.city}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, city: e.target.value })}
                    placeholder="City *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="address-level2"
                  />
                  <input
                    type="text"
                    value={companyInfo.state}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, state: e.target.value })}
                    placeholder="State *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="address-level1"
                  />
                  <input
                    type="text"
                    value={companyInfo.postal_code}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, postal_code: e.target.value })}
                    placeholder="Postal Code *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="postal-code"
                  />
                  <input
                    type="text"
                    value={companyInfo.country}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, country: e.target.value })}
                    placeholder="Country *"
                    className="border p-2 mb-4 w-full"
                    autoComplete="country"
                  />
                  <input
                    type="text"
                    value={companyInfo.vat_number}
                    onChange={(e) => setCompanyInfo({ ...companyInfo, vat_number: e.target.value })}
                    placeholder="VAT Number (Optional)"
                    className="border p-2 mb-4 w-full"
                    autoComplete="off"
                  />
                  <div className="text-black-500 text-center p-3">
                    <label>
                      <input
                        type="checkbox"
                        checked={agreeToPolicies}
                        onChange={(e) => setAgreeToPolicies(e.target.checked)}
                        className="mr-2"
                      />
                      By clicking submit, you agree to all
                      <a href="#" className="text-blue-400"> Terms of Service</a>,
                      <a href="#" className="text-blue-400"> Privacy Policy</a>, and
                      <a href="#" className="text-blue-400"> Data Sharing Policies</a>.
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <button type="button" onClick={handlePrevStep} className="bg-gray-500 text-white p-2 rounded">Back</button>
                    <button type="submit" className="bg-blue-500 text-white p-2 rounded" disabled={!agreeToPolicies}>Submit</button>
                  </div>
                </div>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
        <div className="text-gray-500 text-center p-3">
          If you already have an account, please login
          <a href="/login" className="text-blue-400"> HERE.</a>
        </div>
      </form>
    </div>
  );
}

export default Signup;