// dash-transactions.js
import React from 'react';
import moment from 'moment';

function TransactionsTable({ currentTransactions, isDarkMode, generateInvoice }) {
  return (
    <div className="overflow-x-auto">
      <table className={`min-w-full border ${isDarkMode ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-200'}`}>
        <thead>
          <tr>
            <th className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>Transaction ID</th>
            <th className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>Amount</th>
            <th className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>Date</th>
            <th className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>Status</th>
            <th className={`py-2 px-4 border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>Receipt Link</th>
          </tr>
        </thead>
        <tbody>
          {currentTransactions.map(transaction => (
            <tr key={transaction.id} className={`border-b ${isDarkMode ? 'border-gray-600' : 'border-gray-200'}`}>
              <td className="py-2 px-4">{transaction.id}</td>
              <td className="py-2 px-4">{transaction.amount}</td>
              <td className="py-2 px-4">{moment(transaction.date).format('YYYY-MM-DD')}</td>
              <td className="py-2 px-4">
                <span className={`inline-flex text-xs font-medium px-2.5 py-0.5 rounded-full ${
                  transaction.status === 'succeeded' ? (isDarkMode ? 'bg-green-600 text-green-200' : 'bg-green-300 text-green-600') :
                  transaction.status === 'incomplete' ? (isDarkMode ? 'bg-yellow-600 text-yellow-200' : 'bg-yellow-300 text-yellow-600') :
                  (isDarkMode ? 'bg-red-600 text-red-200' : 'bg-red-300 text-red-600')
                }`}>
                  {transaction.status}
                </span>
              </td>
              <td className="py-2 px-4">
                <a href={transaction.receipt_url} target="_blank" rel="noopener noreferrer" className="text-blue-500">Receipt</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TransactionsTable;